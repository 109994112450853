import { render, staticRenderFns } from "./VolumeIcon.vue?vue&type=template&id=0a2ee0f8&"
import script from "./VolumeIcon.vue?vue&type=script&lang=js&"
export * from "./VolumeIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/alexbilyk/Projects-Apps/pa/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a2ee0f8')) {
      api.createRecord('0a2ee0f8', component.options)
    } else {
      api.reload('0a2ee0f8', component.options)
    }
    module.hot.accept("./VolumeIcon.vue?vue&type=template&id=0a2ee0f8&", function () {
      api.rerender('0a2ee0f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/VolumeIcon.vue"
export default component.exports