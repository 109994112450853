var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 22 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10 1.07031L5 5.07031H1V11.0703H5L10 15.0703V1.07031Z",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.07 1C19.9447 2.87528 20.9979 5.41836 20.9979 8.07C20.9979 10.7216 19.9447 13.2647 18.07 15.14M14.54 4.53C15.4774 5.46764 16.0039 6.73918 16.0039 8.065C16.0039 9.39082 15.4774 10.6624 14.54 11.6",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }