<template>
  <svg :width="width" :height="height" :color="color" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 1.07031L5 5.07031H1V11.0703H5L10 15.0703V1.07031Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M18.07 1C19.9447 2.87528 20.9979 5.41836 20.9979 8.07C20.9979 10.7216 19.9447 13.2647 18.07 15.14M14.54 4.53C15.4774 5.46764 16.0039 6.73918 16.0039 8.065C16.0039 9.39082 15.4774 10.6624 14.54 11.6"
      :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VolumeIcon',
  props: {
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    stroke: {
      type: String,
      default: '#3B86F7',
      required: false
    },
    width: {
      type: Number,
      default: 18,
      required: false
    },
    height: {
      type: Number,
      default: 18,
      required: false
    }
  }
}
</script>
