<template>
  <svg :width="width" :height="height" viewBox="0 0 41 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" :width="width" :height="height" rx="10" :fill="color" />
    <circle cx="10.5" cy="10" r="6" fill="white" />
  </svg>
</template>
<script>
export default {
  name: 'SwitchOffIcon',
  props: {
    color: {
      type: String,
      default: '#B2B2B2'
    },
    stroke: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 41
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>
